import Vue from 'vue'
import axios from 'axios';
import qs from 'qs';
import { Loading } from 'element-ui';
import store from '../store/index'
let api = {};
let obj = {};
const map = {
  development: 'http://127.0.0.1:8081',
  // development: 'http://119.167.230.199:8081',
  // production: 'http://8.136.203.140:8080'
  production: 'https://7haigongyi.com/api'
  // production: 'https://7haigongyi.com/api'
}
const wsMap = {
  development: 'ws://127.0.0.1:8081',
  // development: 'ws://119.167.230.199:8081',
  // production: 'ws://8.136.203.140:8080'
  production: 'wss://7haigongyi.com/websocket'
}
api.root_path = map[process.env.NODE_ENV] || 'http://127.0.0.1:8081'
// api.root_path = process.env.BASE_API;
// api.root_path = "http://153.126.139.58:8080";    //正式服
// api.root_path = "https://7haigongyi.com/api";    //正式服
// api.root_path = "http://119.167.230.199:8081";    //测试服
// api.root_path = "https://localhost/api";    //本地测试
Vue.prototype.wsUrl = wsMap[process.env.NODE_ENV] || 'ws://127.0.0.1:8081';

api.axios_instance = null;
api.timeout = 60000
api.axios = function () {
  if (!api.axios_instance) {
    var instance = axios.create({
      baseURL: api.root_path,
      timeout: api.timeout
    });
    instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
    // 添加请求拦截器
    instance.interceptors.request.use(function (config) {
      const token = localStorage.getItem('token');
      //  const token =store.state.user.token;
      if (token) {
        config.headers.common['Authorization'] = 'Bearer ' + token
      }
      return config
    }, (error) => {
      return Promise.reject(error)
    })

    // 添加响应拦截器
    instance.interceptors.response.use(function (response) {
      // 对响应数据做点什么
      if (response.data.status != 0 && response.data.status != 500 && response.data.status != 412 && response.data.status != 406) {
        // console.log('请求出错');     

        redirect('/error')
      } else if (response.data.status == 500) {

        Vue.prototype.$message.warning(response.data.msg);
      }
      else {

      }
      return response;
    }, function (error) {
      // 对响应错误做点什么
      return Promise.reject(error);
    });
    api.axios_instance = instance;
  }
  return api.axios_instance;
}

api.get = function (url, params) {
  return api.axios().get(url, params)
};
api.post = function (url, data) {
  return api.axios().post(url, data)
};
api.put = function (url, data) {
  return api.axios().put(url, data)
};
api.delete = function (url, params) {
  return api.axios().delete(url, params)
};
api.submit = function (url, data) {
  return api.axios().post(url, data, { headers: { 'Content-Type': 'multipart/form-data' } })
}
api.postJson = function (url, data) {
  return api.axios().post(url, qs.stringify(data), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
}
api.putJson = function (url, data) {
  return api.axios().put(url, qs.stringify(data))
}


obj.install = function (Vue, options) {
  Vue.prototype.$axios = api
}
export default obj;

