<template>
  <div id="app">
    <header-teacher v-if="teacherToken"></header-teacher>
    <header-common v-else></header-common>
    <keep-alive :include="include">
      <router-view/>
    </keep-alive>
    <footer-common></footer-common>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import headerCommon from './components/header-common'
import headerTeacher from './components/header-teacher'
import footerCommon from './components/footer-common'
export default {
  components:{
    headerCommon,
    headerTeacher,
    footerCommon
  },
  data(){
    return{
      include:[]
    }
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      accountType: state => state.user.accountType,
      studentId: state => state.user.studentId,
      companyId: state => state.user.companyId,
      // avatar: state => state.user.avatar,
      teacherToken: state => state.teacher.token,
    })
  },
  methods:{
    isMobile() {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone",
                    "SymbianOS", "Windows Phone",
                    "iPad", "iPod"];
        var flag = false;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) >= 0) {
                flag = true;
                break;
            }
        }
        this.$store.dispatch('user/saveIsMobile',flag)
        return flag;
    }
  },
  created(){
    this.isMobile();
  },
  watch:{
    $route(to,from){
      //如果 要 to(进入) 的页面是需要 keepAlive 缓存的，把 name push 进 include数组
      if(to.meta.keepAlive){
        !this.include.includes(to.name) && this.include.push(to.name);
      }
      //如果 要 form(离开) 的页面是 keepAlive缓存的，
      //再根据 deepth 来判断是前进还是后退
      //如果是后退 则 删除 include数组 中的此name
      if(from.meta.keepAlive && to.meta.deepth && (to.meta.deepth < from.meta.deepth)){
        var index = this.include.indexOf(from.name);
        index !== -1 && this.include.splice(index,1);
      }else if(from.meta.keepAlive && !to.meta.deepth){
        var index = this.include.indexOf(from.name);
        index !== -1 && this.include.splice(index,1);
      }
    }
  }
}
</script>