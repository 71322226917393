import {getDefaultAvatar, handleAvatar} from '../../utils'

const state = {
  token: '',
  accountId: '',
  username:'',
  avatar:'',
  teacherInfo:'',
  teacherArea:'',//该用户在中国还是在日本 1中国2日本
  accountType:'',
}

const getters = {
  avatar(state){
    return state.avatar
  },
  userInfo(state) {
    return state.userInfo
  }
}

const mutations = {
  save_user(state, info) {
    state.teacherArea = info.area
    localStorage.setItem('teacherArea',state.teacherArea)
    // console.log(state,'state')
    console.log(info,'info')
    localStorage.setItem('accountId', info.id)
    state.token = info.token;
    state.username = info.username;
    state.avatar = info.avatar ?? getDefaultAvatar(info.teacher && info.teacher.gender, true);
    state.accountType = info.accountType;
    localStorage.setItem('accountType', info.accountType)
    localStorage.setItem('avatar', state.avatar)
    if (state.token) {
      localStorage.setItem('token', state.token);
    }
    if (info.teacher) {
      state.userInfo = info.teacher;
    }
  },
  save_avatar(state, data){
    state.avatar = data;
  }
}

const actions = {
  saveUser({ commit }, params) {
    commit('save_user', params)
  },
  saveAvatar({ commit }, params) {
    commit('save_avatar', params)
  },
}

export default {
  namespaced:true,//用于在全局引用此文件里的方法时标识这一个的文件名
  state,
  getters,
  mutations,
  actions
}