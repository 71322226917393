<template>
  <div>
    <!-- 老师登录的头部 -->
    <div class="page_header media_display">
      <div class="page_headerAll">
        <div class="container_box">
          <el-row :gutter="20">
            <el-col :sm="4" :xs="10">
              <img src="../assets/images/Logo.png" class="icon" @click="toteacherCenter"/>
            </el-col>
            <el-col :sm="14" class="header_center media_display" v-show="token">
              <div class="teacher_center" @click="toteacherCenter">志愿者中心</div>
            </el-col>
            <el-col class="teacher_right" :sm="6" :xs="14" style="text-align:right"  v-show="token">
              <div class="img_news">
                <img src="../assets/images/news.png" class="person_new" @click="gotoNewsCenter">
                <span class="news_warn" v-if="token && newsNum !== 0" @click="gotoNewsCenter">{{newsNum}}</span>
              </div>
              <div class="avatar_img" v-if="avatar" :style="{backgroundImage:'url('+avatar+')'}"></div>
              <div v-else class="avatar_img"></div>
              <!-- <img :src="avatar" v-if="avatar" class="avatar_img">
              <img src="../assets/images/avther.jpg" v-else class="avatar_img"> -->
              <span class="login_btn" @click="getQuitTeacher">退出</span>
            </el-col>
          </el-row>
        </div> 
      </div>      
    </div>

    <div class="page_header media_display2">
      <div class="page_headerAll">
        <div class="container_box"> 
          <el-row :gutter="20">
            <el-col :sm="4" :xs="10">
              <img src="../assets/images/Logo.png" class="icon"  @click="toteacherCenter"/>
            </el-col>
            <!-- <el-col :sm="14" class="header_center media_display" v-show="token">
              <div class="teacher_center">志愿者中心</div>
            </el-col> -->
            <el-col class="teacher_right" :sm="6" :xs="14" style="text-align:right"  v-show="token">
              <div class="img_news">
                <img src="../assets/images/news.png" class="person_new" @click="gotoNewsCenter">
                <span class="news_warn" v-if="token && newsNum !== 0" @click="gotoNewsCenter">{{newsNum}}</span>
              </div>
              <!-- <img :src="avatar" v-if="avatar" class="avatar_img">
              <img src="../assets/images/avther.jpg" v-else class="avatar_img"> -->
              <div class="avatar_img" v-if="avatar" :style="{backgroundImage:'url('+avatar+')'}"></div>
              <div v-else class="avatar_img"></div>
              <span class="login_btn" @click="getQuitTeacher">退出</span>
            </el-col>
          </el-row>
        </div> 
      </div>      
    </div>
    <!-- <div class="page_footer teacher_footer">
      <div class="container_box">
        <el-row>
          <el-col :sm="8" :xs="24">
            <img src="../assets/images/icon2.png" class="icon icon_foot"  />
            <div class="copyright">
              <div>版权所有&copy;青岛红柿子软件技术有限公司</div>
              <div>沪ICP备100000000号</div>
            </div>
          </el-col>
          <el-col :sm="10" :xs="24">
            <div class="link">
              <div>友情链接</div>
              <div>
                <a target="black" href="https://www.fmprc.gov.cn/web/">中华人民共和国外交部</a>
                <a target="black" href="https://www.fmprc.gov.cn/ce/cejp/chn/default.htm">中华人民共和国驻日本大使馆</a>
              </div>
            </div>
          </el-col>
          <el-col :sm="6" :xs="24">
            <div class="info_foot">
              <div>
                客服电话:123456789
              </div>
              <div>
                工作时间:周一至周五 9:00~18:00
              </div>
              <div>
                地址:青岛市崂山区株洲路
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div> -->

    
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      btnShow: false,
      searchKey: '',
      isShow: false,//小屏幕路由显示
      seen:false,
      commonShow:false,
      teacherType:0,
      ws:null,
      newsNum:0
    }
  },
  methods: {
    toteacherCenter(){
      this.$router.push('/teacher_center/teacher_book')
    },
    gotoNewsCenter() {
      this.$router.push('/news_center_teacher')
    },
    //首页
    goHome() {
      this.$router.push({ path: '/' })
      if (window.screen.width <= 767) {
        this.isShow = !this.isShow
      }
    },
    //师资团队
    goTeam() {
      this.$router.push({ path: '/team' })
      if (window.screen.width <= 767) {
        this.isShow = !this.isShow
      }
    },
    //公益板块
    goCourse() {
      this.$router.push({ path: '/course' })
      if (window.screen.width <= 767) {
        this.isShow = !this.isShow
      }
    },
    //公益心声
    goLearn() {
      if (window.screen.width <= 767) {
        this.isShow = !this.isShow
      }
      this.$router.push({ path: '/learn' })
    },
    goAbout(){
      this.$router.push({path:'/about-us'})
      if (window.screen.width <= 767) {
        this.isShow = !this.isShow
      }
    },
    goNewsList(){
      if (window.screen.width <= 767) {
        this.isShow = !this.isShow
      }
      this.$router.push({ path: '/newslist' })
    },
    goPersonInfo(){
      if (window.screen.width <= 767) {
        this.isShow = !this.isShow
      }
      this.$router.push({ path: '/personal-info' })
    },
    //教师退出
    getQuitTeacher(){
      this.$confirm('确定要退出登录？', '确认', {
          confirmButtonText: '退出',
          cancelButtonText: '取消',
        }).then(() => {
          localStorage.removeItem('token')
          localStorage.removeItem('teacherId'); 
          localStorage.removeItem('teacherArea'); 
          this.$store.dispatch('teacher/saveUser','')     
          this.$router.push({ path: '/teacher_center/teacher_login' });
          this.$message({
            type: 'success',
            message: '退出成功!'
          });
        }).catch(() => {          
      }); 
    },
    connectWs(){
      if ("WebSocket" in window){
        let wsUrl = this.wsUrl
        let accountId =localStorage.getItem('accountId')
        if(accountId && accountId !== 'undefined'){
          
          // 打开一个 web socket
          this.ws = new WebSocket(`${wsUrl}/ws/not-read-msg-num/${accountId}`);
          this.ws.onopen = function(){
            // Web Socket 已连接上，使用 send() 方法发送数据
            console.log('连接成功')
          };
          var that = this
          this.ws.onmessage = function (evt) { 
            var received_msg = evt.data;
            var jsonObject = JSON.parse(received_msg)
            that.newsNum = jsonObject.data  
          };
          
          this.ws.onclose = function(){ 
            // 关闭 websocket
            console.log("连接已关闭..."); 
          };
        } 
      } else {
        // 浏览器不支持 WebSocket
        alert("您的浏览器不支持 WebSocket!");
      }
    },
    getNews(){
      var accountId =localStorage.getItem('accountId')
      if(accountId && accountId !== 'undefined') {
        this.$axios.get('/message/num/'+accountId).then(res=>{
          this.newsNum = res.data.data
          // console.log(this.newsNum)
        })
      }
    },
  },
  computed: {
    ...mapState({
      token:state=>state.teacher.token,
      // avatar: state => state.teacher.avatar,
    }),
    ...mapGetters({
        avatar:'teacher/avatar'
    })
  },
  mounted(){
    this.$bus.on('login',()=>{
      this.getNews()
      // console.log(this.newsNum,1111)
      if(!this.ws) {
        this.connectWs()
      }
    })
    this.getNews()
    this.connectWs()
  },
  created() {
    
  }
}
</script>

<style>
/* html {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
} */
</style>
