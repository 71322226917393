import Vue from 'vue'

export const getDefaultAvatar = (gender = 1, isTeacher = true) => {
    const pictures = isTeacher ? [ 'tm.jpg', 'tw.jpg' ] : [ 'sm.jpg', 'sw.jpg' ]
    const path = gender === 1 ? pictures[0] : pictures[1]
    return Vue.prototype.$axios.root_path + '/imgs/' + path
}
export const handleAvatar = (item = {}, isTeacher = true) => {
    const img = new Image()
    img.src = item.avatar
    img.onerror = () => {
        item.avatar = getDefaultAvatar(item.gender, isTeacher)
    }
}

export const smoothScrollW = function (element, offset, duration) {
    let startPosition = element.scrollLeft
    let targetPosition = startPosition + offset
    let startTime = null

    function animation(currentTime) {
        if (startTime === null) startTime = currentTime;
        let elapsedTime = currentTime - startTime
        let progress = Math.min(elapsedTime / duration, 1)
        // 使用 easeInOutQuad 缓动函数，你也可以选择其他缓动函数
        let easeInOutQuad = function (t) {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        };
        element.scrollLeft = startPosition + (targetPosition - startPosition) * easeInOutQuad(progress);
        if (progress < 1) {
            requestAnimationFrame(animation);
        } else {
            element.scrollLeft = targetPosition; // 确保最终位置精确
        }
    }
    requestAnimationFrame(animation);
}
